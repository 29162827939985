import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Main
const Login = () => import('@/views/Login')
const Dashboard = () => import('@/views/Dashboard')
const Profile = () => import('@/views/Profile')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

// Sites
const Sites = () => import('@/views/sites/Sites')
const Site = () => import('@/views/sites/Site')

// Menus
const Menus = () => import('@/views/menus/Menus')
const Menu = () => import('@/views/menus/Menu')

// Financial Reports
const Reports = () => import('@/views/reports/Reports')
const Detail = () => import('@/views/reports/Detail')

Vue.use(Router)

let router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/login',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'profile',
          name: 'User Profile',
          component: Profile
        },        
        {
          path: 'users',
          meta: { label: 'Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'User Management',
              component: Users
            },
            {
              path: ':id',
              name: 'User Details',
              component: User
            }
          ]
        },
        {
          path: 'sites',
          meta: { label: 'Sites'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Site Management',
              component: Sites
            },
            {
              path: ':id',
              name: 'Site Details',
              component: Site
            }
          ]
        },
        {
          path: 'menus',
          meta: { label: 'Menus'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Menu Management',
              component: Menus
            },
            {
              path: ':id',
              name: 'Menu Item Details',
              component: Menu
            }
          ]
        },
        {
          path: 'reports',
          meta: { label: 'Reports'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Reports',
              component: Reports
            },
            {
              path: ':id',
              name: 'Order Details',
              component: Detail
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '',
          name: 'Login',
          component: Login
        },
      ]
    }
  ]
})

export default router