// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logo = ['400 200', `
<g>
	<path class="st0" d="M54,41.4V160c0,24.1-10.5,39.7-32.4,39.7c-7.1,0-14.6-2-21.5-6.5l8.7-18.8c4.2,2,8.1,3,11.7,2.8
		c7.9-0.4,9.7-6.5,9.7-17.2V41.4H54z M27.9,14.1c0,18.8,28.5,18.8,28.5,0S27.9-4.7,27.9,14.1z"/>
	<path class="st0" d="M171.4,90.6c0,28.1-19.2,50.8-50.8,50.8S70,118.7,70,90.6c0-27.9,19.4-50.8,50.4-50.8
		C151.4,39.8,171.4,62.7,171.4,90.6z M94.1,90.6c0,14.8,8.9,28.7,26.5,28.7s26.5-13.8,26.5-28.7c0-14.6-10.3-28.9-26.5-28.9
		C103.2,61.7,94.1,76,94.1,90.6z"/>
	<path class="st0" d="M264.5,31.9l17.8,13.4l-10.9,13.8c7.5,8.5,10.3,18.2,10.3,28.9c0,12.1-4.5,29.1-20.6,36.4
		c16.2,8.1,20.2,19.8,20.2,32.2c0,26.9-20.6,43.5-48.8,43.5s-49.4-17.2-49.4-43.5H207c0,12.7,11.7,21,25.5,21s24.7-7.5,24.7-21
		s-12.7-19.6-24.7-19.6c-30.4,0-49.4-18.6-49.4-49c0-30.4,22.1-49.4,49.4-49.4c7.7,0,15.6,1,22.3,5.5L264.5,31.9z M207,88
		c0,17,11.5,27.1,25.5,27.1c13.8,0,25.3-10.3,25.3-27.1c0-16.8-11.5-27.5-25.3-27.5C218.5,60.5,207,71,207,88z"/>
	<path class="st0" d="M373.6,31.9l17.8,13.4l-10.9,13.8c7.5,8.5,10.3,18.2,10.3,28.9c0,12.1-4.5,29.1-20.6,36.4
		c16.2,8.1,20.2,19.8,20.2,32.2c0,26.9-20.6,43.5-48.8,43.5c-28.3,0-49.4-17.2-49.4-43.5h23.9c0,12.7,11.7,21,25.5,21
		s24.7-7.5,24.7-21s-12.7-19.6-24.7-19.6c-30.4,0-49.4-18.6-49.4-49c0-30.4,22.1-49.4,49.4-49.4c7.7,0,15.6,1,22.3,5.5L373.6,31.9z
		 M316.1,88c0,17,11.5,27.1,25.5,27.1c13.8,0,25.3-10.3,25.3-27.1c0-16.8-11.5-27.5-25.3-27.5C327.6,60.5,316.1,71,316.1,88z"/>
</g>
`]

export const minimized_logo = ['459.1 473.1',`

<g>
	<path class="st0" d="M161.3,162.7H60c-3.8,0-6.9-3.1-6.9-6.9c-0.6-4.4,2.1-9,6.9-8.9c0,0,101.3,0,101.3,0
		C170.5,146.8,170.6,162.8,161.3,162.7z"/>
	<path class="st0" d="M121.4,202.8H20.2c-3.8,0-6.9-3.1-6.9-6.9c-0.6-4.4,2.1-9,6.9-8.9c0,0,101.3,0,101.3,0
		C130.7,186.9,130.7,202.9,121.4,202.8z"/>
	<path class="st0" d="M143.7,242.9H42.5c-3.8,0-6.9-3.1-6.9-6.9c-0.6-4.4,2.1-9,6.9-8.9c0,0,101.3,0,101.3,0
		C153,226.9,153,243,143.7,242.9z"/>
</g>
<g>
	<path class="st0" d="M383.8,101.7L383.8,101.7C383.8,101.6,383.8,101.7,383.8,101.7z"/>
	<path class="st0" d="M422.1,112.2c-1.3-2.4-16.3-17.6-23.3-17.7c-6,1.5-11.4,4.8-15.1,7.3c0,0.1,0,0,0,0c-1.1,0.7-2.3,1.5-3.5,2.4
		l-1.5,1l-1.1,0.9c-4,3.4-4.4,8.1-4,10.5c0,0,0,0,0,0.1c-25.1,10-52.3,20.3-58.8,22c-4-2.1-16.1-10.2-26-16.8
		c-1.7-1.1-3.3-2.2-5-3.3c5.1-3.1,16.6-11.1,26.1-23.2c8.2-10.5,11-28.4,6.8-43.5c-3.3-12-10.8-20.8-20.9-24.7
		c-27.3-10.6-43.7-1-50,11.4c-7.2,14.3-4.5,26.7-2.4,36.7c0.9,4.1,1.7,8,1.8,11.6c0,1.3,0.8,4.4-0.2,6c-0.2,0.3-0.5,0.8-0.9,1.2
		c-0.8-0.3-1.5-0.6-2.1-0.7c-11.2-3.1-20.6,9-25.4,18.9c-10.9,11.9-32.2,36.5-33.7,46.1c-1.4,9.3,7.9,25.4,13.7,34.6
		c-4,15.3-7.9,29.8-10.7,39.9c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.5-0.3,0.9-0.4,1.4c0,0-1.9,6.5-2.1,7.5c-0.6,2.5-1.2,4.7-1.3,5
		c-6.9,22.6-28.9,54.8-35,61.6c-30.3,10.3-56.7,37.6-62.6,44c-4.6-1-9.5-0.7-13-0.5c-1,0.1-2,0.1-2.8,0.1c-4.7,0.1-8.7,1.8-11.6,5
		c-5.5,5.9-5.1,14.8-5,15.4c0,0,0.1,39.3,0.1,53.1c0,7.9,2.1,13.9,6.2,17.7c4,3.7,8.5,4.1,10.3,4.1c0.1,0,0.2,0,0.3,0
		c19.9-0.1,22.2-26.9,23.2-38.3c0.1-1.2,0.2-2.3,0.3-3.1c0.4-3.1,5.2-7.5,8-10.2c2.2-2,3.9-3.6,5-5.4c52-9.9,95.5-59.5,107-76.5
		c4.6-6.9,8.5-7.7,10-7.2c3.9,3.1,9.6,5.8,16,8.2c8.5,3.6,18.7,6.8,30.8,9c-2.1,3.6-5.7,8.7-11.7,15.3c-8.9,9.8-13,16.6-12.8,22.7
		c-5.6,2.2-10.3,7.1-13.4,10.4c-0.7,0.7-1.3,1.4-1.9,2c-3.3,3.3-5,7.3-4.9,11.6c0.2,8.1,6.6,14.2,7,14.6c0,0,27.2,28.3,36.8,38.3
		c5.4,5.6,10.9,8.5,16.5,8.5c0.1,0,0.2,0,0.3,0c5.8-0.1,9.4-3.3,10.5-4.4c14.3-13.9-2.5-34.7-9.8-43.7c-0.8-1-1.5-1.8-2-2.5
		c-1.9-2.5-1.5-9-1.3-12.9c0.1-2.3,0.3-4.2,0.1-5.9c12.9-8.7,49.5-36.8,43.7-69.8c-6.5-37-46.4-63.5-58.9-69.7
		c3.1-4.7,7.2-11.7,12.7-22.3c6.2-12.1,13.3-28.2,16.1-42.9c0.3,0.1,0.5,0.2,0.8,0.2c4.4,1.3,8.7,2.3,13.1,3.1
		c12.4,2.4,52.7-18,73.7-29.3c7.6-5.4,15.1-9.1,18-10.4c-0.1,0-0.2,0-0.3,0c13.2-4.1,18.4-12.1,20.2-15.7
		C422.4,120.2,424,115.7,422.1,112.2z M256.7,44c5-10,18-12,34.8-5.5c8,3.1,11.7,10.1,13.4,15.8c-19.2-6.1-40.3-4.9-50.7-3.7
		C254.8,48.4,255.6,46.3,256.7,44z M306.6,67.8c-0.2,7.8-2.4,15.4-6.1,20.2c-7.8,10-17.8,17.9-24.6,23.9l-21.6-12.9
		c3.3-5.1,3.2-8.4,3.1-12.2c-0.1-4.7-1.1-9.4-2-13.9c-0.7-3.4-1.4-6.7-1.8-10C262.6,61.7,287.3,59.6,306.6,67.8z M193.8,160
		c0.5-3.2,7.5-12.9,16.6-23.8c-2.2,10.9-5.9,26.1-9.9,41.8C196.3,170.1,193.3,162.9,193.8,160z M204.5,210.4
		c9.3,3.8,29,10.3,58.4,12.4c-4,7.1-7.5,12.5-9.2,14.9c-23.1,3-45.3-3.2-54.8-6.5C200.5,225.2,202.5,218.1,204.5,210.4z M92,388
		c-4.6,4.2-10.9,10.1-11.8,17.6c-0.1,0.9-0.2,2.1-0.3,3.5c-0.7,8.1-2.3,27.2-11.2,27.2l0,0l-0.2,0c-2.1,0-4.4-2.6-4.3-9.7
		c0.1-13.9-0.1-53.2-0.1-53.6c-0.1-1.5,0.3-5.2,1.8-6.9c0.4-0.5,1.2-1,2.9-1.1c1,0,2-0.1,3.2-0.1c1.4-0.1,3.1-0.2,4.8-0.2
		c1.8,0,3.7,0.1,5.2,0.4c1.3,3.4,3.7,7.1,6.1,10.9c2.1,3.3,4.3,6.9,5.9,10.1C93.2,386.8,92.5,387.4,92,388z M286.7,435.4l-0.3,0.4
		c0,0-0.8,0.6-1.9,0.6c-1.2,0-3.8-0.6-7.8-4.7c-9.6-10.1-36.9-38.4-37.1-38.6c-1.1-1-3.4-3.9-3.4-6.2c0-0.7,0.1-1.6,1.4-2.8
		c0.7-0.7,1.4-1.5,2.2-2.3c2.7-2.8,6.8-7.3,9.7-7.6c4.6,4.8,14.6,7.1,20.8,8.1c0,0.7-0.1,1.4-0.1,2.1c-0.4,6.3-0.9,14.8,3.7,20.9
		c0.6,0.7,1.3,1.7,2.2,2.8C281.1,414.3,293.2,429.2,286.7,435.4z M308.1,294.6c-10.7-6.5-22.8-8.3-23.6-8.4
		c-3.3-0.4-6.3,1.8-6.8,5.1c-0.5,3.3,1.8,6.4,5.1,6.8c6.9,1,25.5,6.2,29.2,18.2c0.4,1.4,1.3,2.5,2.4,3.2
		c-2.5,23.8-32.1,45-40.4,50.6c-1.7-0.7-3.6-1.3-5.7-1.9c-3.8-1.2-8.1-2.6-11.2-4.4c0,0,0,0-0.1,0c-1-2.3,0.1-5.6,9.1-15.5
		c14.9-16.3,17.2-25.4,17.5-27.1l1.1-6l-6-1.1c-7-1.3-22.3-4.6-34.5-8.9c-25.4-10.4-34.4-24.9-34.6-25.2c-1.7-2.9-5.3-3.8-8.2-2.2
		c-2.9,1.7-3.9,5.4-2.2,8.2c0.3,0.5,3.6,6.1,11.5,13c-2.9,2-5.8,4.9-8.6,9.1c-8.6,12.9-49.8,61.3-98.1,71.1
		c-0.7-1.4-1.5-2.8-2.3-4.3c-1.9-3.5-4.1-7.5-5.1-10.9c-0.5-1.6-1.1-3-1.9-4.2c7.8-8.1,31.6-31.2,57.3-39.4l0.6-0.2
		c1.3,4.4,4,8.6,9.1,10.1c0.6,0.2,1.1,0.2,1.7,0.2c2.6,0,5.1-1.7,5.9-4.4c0.9-3.2-0.9-6.5-4.1-7.4c-1.1-0.9-1.9-6-1.6-8.8
		c0.2-1.1,0.1-2.1-0.3-3.1c10.5-16,26.6-43.8,32.2-63.2c0.1-0.2-0.9,3.2,0.2-0.8c8.9,3,25.8,7.7,45.3,7.7c5.5,0,11.2-0.4,17-1.3
		c0.2,0,0.3-0.1,0.5-0.1c0.9,0.2,1.7,0.5,2.2,0.7C266.9,252.8,295,269.9,308.1,294.6z M281.9,180.9c-21.1,0.4-36.6-4.5-36.7-4.5
		c-3.2-1-6.6,0.7-7.6,3.9c-1,3.2,0.7,6.6,3.9,7.6c0.7,0.2,15.3,4.9,36.2,5.1c-2.5,6.2-5.4,12.4-8.3,18.1
		c-32.7-1.6-53.7-9.1-61.6-12.5c5.8-22.2,12-46.6,15-62.1c12,8.5,35.9,24.2,61.7,34C283.8,173.9,282.9,177.4,281.9,180.9z M312,166
		c-5.2-0.9-10.5-2.3-15.8-4.1c-6.9-2.2-13.7-5-20.3-8.1c-8-3.7-15.7-7.9-22.6-11.9c-11.4-6.7-20.8-13.2-26.2-17.1
		c-0.8-1.2-0.7-2.5-0.5-3.6c4.3-9.7,9.9-16.9,12.2-16.3c5.4,1.5,28.3,16.8,43.4,26.9c24.8,16.6,28.6,18.9,31.9,19
		c1.4,0,5.6,0.2,63.1-22.7c1.5,1.5,3,2.9,4.3,4.3c0.1,1.1,0.3,2.2,0.4,3.2C362.7,146.2,320,167.5,312,166z M395.5,127.2
		c-0.6-0.8-1.4-1.7-2.2-2.9c-2-2.7-4.6-6.2-7-9.7l0.9-0.6c3-2.1,5.4-3.7,7.4-5c1.6-1,3-1.8,3.9-2.2c3,1.8,9.3,7.3,12.1,10.2
		C409.5,119.5,405.5,124.7,395.5,127.2z"/>
</g>
<rect x="349" y="94" class="st0" width="99.3" height="12.1"/>
<path class="st0" d="M398.7,48c-24,0-44,16.5-49.5,38.8h98.9C442.7,64.5,422.6,48,398.7,48z"/>
<path class="st0" d="M398.7,38c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5S402.3,38,398.7,38z M398.7,47.4
	c-1.6,0-2.9-1.3-2.9-2.9c0-1.6,1.3-2.9,2.9-2.9c1.6,0,2.9,1.3,2.9,2.9C401.5,46.1,400.3,47.4,398.7,47.4z"/>


	`]
