import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    selectedSite:null,
    selectedCategory:null,
    permissions:{},
    currentUser: {},
    sidebarShow: "responsive",
    sidebarMinimize: false,
  },

  plugins: [createPersistedState({storage: window.sessionStorage,})],
  mutations: {
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarOpened ? false : "responsive";
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarClosed ? true : "responsive";
    },
    set(state, [variable, value]) {
      state[variable] = value;
    },
    toggle(state, variable) {
      state[variable] = !state[variable];
    },
  },
});
