import {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilUser,
  cilLockLocked,
  cilPeople,
  cilTrash,
  cilPencil,
  cilSave,
  cilPhone,
  cilX
} from '@coreui/icons'

import { cilCity } from '../../../node_modules/@coreui/icons-pro/js/linear/cil-city.js'
import { cilRestaurantMenu } from '../../../node_modules/@coreui/icons-pro/js/linear/cil-restaurant-menu.js'
import { cilDollarCircle } from '../../../node_modules/@coreui/icons-pro/js/linear/cil-dollar-circle.js'
import { cisCaretRight } from '../../../node_modules/@coreui/icons-pro/js/solid/cis-caret-right.js'
import { cisCaretBottom } from '../../../node_modules/@coreui/icons-pro/js/solid/cis-caret-bottom.js'
import { cilEye } from '../../../node_modules/@coreui/icons-pro/js/linear/cil-eye.js'
import { cilEyeSlash } from '../../../node_modules/@coreui/icons-pro/js/linear/cil-eye-slash.js'

import { logo,minimized_logo } from './logo'

import { corner } from './misc'

export const iconsSet = Object.assign({}, {logo, minimized_logo, corner}, {
  cilApplicationsSettings,
  cilDrop,
  cilPuzzle,
  cilMoon,
  cilSpeedometer,
  cilSun,
  cilUser,
  cilLockLocked,
  cilPeople,
  cilTrash,
  cilPencil,
  cilSave,
  cilPhone,
  cilX,
  cilCity,
  cilRestaurantMenu,
  cilDollarCircle,
  cisCaretRight,
  cisCaretBottom,
  cilEye,
  cilEyeSlash
})
