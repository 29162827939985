import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
//import CoreuiVue from '@coreui/vue/src'
import CoreuiVuePro from "@coreui/vue-pro/src/index.js";
import App from "./App";
import router from "./router/index";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import VueSimpleAlert from "vue-simple-alert";
import Amplify from "aws-amplify";
import awsconfig from "./config/aws-exports";
import constants from "./data/Constants.js";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

const AWS = require("aws-sdk");
Amplify.configure(awsconfig);
// Set the region where your identity pool exists
AWS.config.region = constants.AWS_CONFIG_REGION;
AWS.config.paramValidation = false;
// Configure the credentials provider to use your identity pool
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: constants.AUTH_DB_IDENTITYPOOLID,
});
Vue.use(CoreuiVuePro);
Vue.use(VueSimpleAlert);
Vue.use(Loading);
Vue.prototype.$log = console.log.bind(console);

new Vue({
  el: "#app",
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  template: "<App/>",
  components: {
    App
  },
});
