var constants ={}
if(process.env.NODE_ENV.toUpperCase() == "DEVELOPMENT"){
  constants = {
    /*---------------------config start --------------------------------*/
    // Staging or dev
    AWS_CONFIG_REGION: "us-east-2",
    AUTH_DB_IDENTITYPOOLID: "us-east-2:de224538-e0cf-41a6-ba9b-7c8a1c4fb11c",
    S3_ACCESS_KEY_ID:"AKIA5TS5NYUL5TIAMYFA",
    S3_SECRET_ACCESS_KEY:"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",
  
    USERS_TABLE_NAME: "users_test",
    SITES_TABLE_NAME: "sites_test",
    PERMISSIONS_TABLE_NAME: "permissions_test",
    ORDERS_TABLE_NAME: "orders_test",
  
    S3_BUCKET_NAME:"sitelogotest",
  
  /*---------------------config end --------------------------------*/
   
    
  };
  

}else{
  constants = {
    /*---------------------config start --------------------------------*/
    //prod
    AWS_CONFIG_REGION: "us-east-2",
    AUTH_DB_IDENTITYPOOLID: "us-east-2:31757fdf-7027-43a8-8056-73d8b41827b1",
    S3_ACCESS_KEY_ID:"AKIA5TS5NYUL5TIAMYFA",
    S3_SECRET_ACCESS_KEY:"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",
  
    USERS_TABLE_NAME: "users",
    SITES_TABLE_NAME: "sites",
    PERMISSIONS_TABLE_NAME: "permissions",
    ORDERS_TABLE_NAME: "orders",
  
    S3_BUCKET_NAME:"joggportalimages",
  
  /*---------------------config end --------------------------------*/
   
    
  };
 

}
constants.INFORMATION= "Information";
constants.PROFILE_UPDATE_SUCCESS= "Succesfully updated profile info.";
constants.PROFILE_PASSWORD_CHANGE_SUCCESS= "Password changed succesfully.";
constants.USERNAMEVALIDATION= "Please enter username";
constants.PASSWORDVALIDATION= "Please enter password";

constants.NAME_VALIDATION= "Please enter name";
constants.EMAIL_VALIDATION= "Please enter email";
constants.EMAIL_VALID_VALIDATION= "Please enter valid email";
constants.PASSWORD_VALIDATION= "Please enter password";
constants.PASSWORD_OLD_VALIDATION= "Please enter old password";
constants.PASSWORD_VERIFY_VALIDATION= "Please enter verify password";
constants.PASSWORD_VERIFY= "Password and verify password are not same.";
constants.GROUP_VALIDATION= "Please select group";

constants.SITE_ID_VALIDATION= "Site id is required field";
constants.SITE_NAME_VALIDATION= "Name is required field";
constants.SITE_PHONE_VALIDATION= "Phone is required field";
constants.SITE_ADDRESS_VALIDATION= "Address is required field";
constants.SITE_BUILDING_VALIDATION= "Building is required field";
constants.SITE_NEW_BUILDING_VALIDATION= "New building is required field";
constants.SITE_LOCATION_VALIDATION= "Location is required field";
constants.SITE_CITY_VALIDATION= "City is required field";
constants.SITE_STATE_VALIDATION= "State is required field";
constants.SITE_CF_PERCENT_VALIDATION= "Delivery fee is required field"; //delivery fee
constants.SITE_JOGG_COMMISSION_PERCENT_VALIDATION= "Jogg commission is required field";
constants.SITE_DISCOUNT_PERCENT_VALIDATION= "Discount is required field";
constants.SITE_TAX_PERCENT_VALIDATION= "Sales tax is required field";

constants.SITE_PREP_TIME="Prep time is required field";
constants.SITE_PRICE="Price is required field";
constants.SITE_DESCRIPTION="Description is required field";
constants.CATEGORY="Category is required field";

constants.CODE_VALIDATION= "Please enter code";
constants.NEW_PASSWORD_VALIDATION= "Please enter new password";
constants.PHONE_VALIDATION ="Phone is required field";

constants.NO_USER_FOUND= "No user found";
constants.JOGGER_NOT_ALLOWED= "Jogger users are not allowed to login to this site";
constants.DATEDIFF= "To date cannot be lesser then from date.";


export default constants;
