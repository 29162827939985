/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
var awsmobile = {};
if(process.env.NODE_ENV.toUpperCase() == "DEVELOPMENT"){
    awsmobile = {
        "aws_project_region": "us-east-2",
        "aws_cognito_identity_pool_id": "us-east-2:0925ce2c-3147-44e1-a844-55f82e2a3aa5",
        "aws_cognito_region": "us-east-2",
        "aws_user_pools_id": "us-east-2_MuAdKMzi0",
        "aws_user_pools_web_client_id": "4i0ujesnq4oofg272g854k5osp",
        //"aws_user_pools_web_client_secret": "kj9lqraoa488rvn05caebh8t74qmc88lncdo3p1551ohlnauk1h",
        "oauth": {},
        "AWSS3": {
            bucket: 'sitelogotest', //REQUIRED -  Amazon S3 bucket name
            region: 'us-east-2', //OPTIONAL -  Amazon service region
        }
    };
    

}else{
    awsmobile = {
        "aws_project_region": "us-east-2",
        "aws_cognito_identity_pool_id": "us-east-2:8cfe4399-82c2-4b28-a3bf-e0779eb7360f",
        "aws_cognito_region": "us-east-2",
        "aws_user_pools_id": "us-east-2_ieLbiz2zA",
        "aws_user_pools_web_client_id": "6ir0olpi7qjb3ae2q3n9nt4eqi",
        //"aws_user_pools_web_client_secret": "121rhv0hcm7fdopg5rjatvjfn86o6goufm7h1ajpa53qmnurs9vt",
        "oauth": {},
        "AWSS3": {
            bucket: 'joggportalimages', //REQUIRED -  Amazon S3 bucket name
            region: 'us-east-2', //OPTIONAL -  Amazon service region
        }
    };

}

export default awsmobile;
